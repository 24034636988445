import request from "@/lib/request";
import {AxiosRequestConfig, AxiosResponse} from "axios";

export interface IGroupChat {
    chat_id: string
    chat_name: string
    announcement: string
    master: string
    player_union_id: string
    player_nick_name: string
    player_target: number
    member_count: number
    create_at: Date
    disband_at: Date
}

export async function getGroupChats(): Promise<IGroupChat[]> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "growth/wework/group/chats",
        method: 'get',
    } as AxiosRequestConfig));
    return rs.data;
}

export async function groupChatsPut(gc: IGroupChat): Promise<IGroupChat[]> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "growth/wework/group/chat",
        method: 'put',
        data: gc,
    } as AxiosRequestConfig));
    return rs.data;
}
